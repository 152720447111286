<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-0 py-1">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <v-img
                  :src="appLogoTitle"
                  max-width="100px"
                  alt="logo"
                  contain
                  class="me-3 "
                ></v-img>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-1 no-bg-hover"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text>
        <info-card
          :title="dataComputed.sport_name"
          :subtitle="dataComputed.match_date_str"
          :date="`${dataComputed.match_date_str} ${dataComputed.start_time} (${dataComputed.match_time} min)`"
          :location="dataComputed.facility_name"
          :level="dataComputed.match_level_str"
          :price="dataComputed && dataComputed.total > 0 ? `${formatCurrency(dataComputed.total)}` : t('status.free')"
          :gender-category="dataComputed.match_gender_str"
          :start-time="dataComputed.start_time_formatted ? `${dataComputed.start_time_formatted} (${formatTime(dataComputed.reservation_time)})` : ''"
          :cancel-date="dataComputed.cancel_reservation_date_str"
          :is-cancel="dataComputed.reservation_status === 'cancelled'"
          :can-cancel="dataComputed.can_cancel"
          :is-confirm="!!dataComputed.deposit_card"
          :confirm-code="depositCardComputed"
          :confirm-date="dataComputed.cancel_reservation_date_str"
          :is-paid="dataComputed.is_paid === 'Y'"
          :is-now="dataComputed.charge_reservation_date_str === 'now'"
          :payment-status="dataComputed.reservation_status === 'cancelled' ? dataComputed.reservation_status : dataComputed.payment_status"
        />
      </v-card-text>

      <v-card-text style="padding-bottom: 140px;">
        <player-list
          :teams="mapTeams(dataComputed.players, dataComputed.match_players_qty)"
          :player-quantity="dataComputed.match_players_qty"
          :show-actions="true"
          :show-change="dataComputed.is_organizer && (dataComputed.match_status === 'Scheduled')"
          :is-private="privateLanding"
          :is-organizer="dataComputed.is_organizer"
          :is-player="dataComputed.is_player"
          @refresh-match="fetchMatch"
          @download-app="openUpdateDownload"
          @open-game="linkVideo"
        />
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="secondary"
          block
          @click="linkVideo"
        >
          <!-- {{ t('transactions.pay_now') }} -->
          <span v-if="dataComputed && dataComputed.total > 0">{{ `${t('deek_game.join_for')} ${formatCurrency(dataComputed.total)}` }}</span>
          <span v-else>{{ t('status.free') }}</span>
        </v-btn>

        <span
          class="text-center text-sm mt-2 d-flex flex-column"
        >
          <!-- {{ dataComputed.can_cancel ? `${t('reservations.free_cancellation')} ${dataComputed.cancel_reservation_date_str}` : t('reservations.non_refundable') }} -->
          <span>
            {{ t('videos.dont_have_fanaty_yet') }}
          </span>

          <a
            href="javascript:void(0)"
            class="text-decoration-none"
            style="color: #FF3232"
            @click="() =>defaultDownloadAppByOS()"
          >
            {{ t('videos.download_app') }}
          </a>
        </span>
      </v-card-actions>
    </div>

    <download-app
      :is-open.sync="isDialogDownload"
      @open-close="openUpdateDownload"
      @save="defaultDownloadAppByOS"
    />

    <share
      :is-open.sync="isDialogShare"
      :link="dataComputed.share_link"
      :icon-size="28"
      :sport="dataComputed.sport_name"
      :date="dataComputed.reservation_date_str"
      :start-time="dataComputed.start_time_formatted ? `${dataComputed.start_time_formatted} (${dataComputed.reservation_time})` : ''"
      :location="dataComputed.complex_name ? `${dataComputed.complex_name} • ${dataComputed.pitch_name}` : ''"
      @open-close="openUpdateShare"
    />
  </div>
</template>
<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  mdiTrayArrowUp,
  mdiDotsVertical,
  mdiCircle,
  mdiMapMarker,
  mdiCancel,
  mdiPlay,
  mdiStarOutline,
  mdiVideo,
  mdiPencil,
  mdiMovieOpenPlayOutline,
  mdiHelpCircleOutline,
  mdiClose,
} from '@mdi/js'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useRouter, openTab, isAndroid, isIOS, defaultDownloadAppByOS, isDev, formatCurrency } from '@core/utils'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { reportIssue, logUserActivity, getMatchGameCode, putCancelReservation, putHoldCardReservation } from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import useVideoData from '@core/utils/useVideoData'
import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import useShare from '@/@core/utils/useShare'
import { error, success } from '@/@core/utils/toasted'

import InfoCard from '@/views/game/components/info-card/InfoCard.vue'
import PlayerList from '@/views/game/components/player-list/PlayerList.vue'
import DownloadApp from '@/views/reservation/components/download-app/DownloadApp.vue'
import Share from '@/views/components/share/Share.vue'

import useGame from './useGame'

export default {
  name: 'Game',
  components: {
    AppBarUserAvatar,
    PlayerList,
    DownloadApp,
    InfoCard,
    Share,
  },
  setup() {
    const { route, router } = useRouter()
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const { shareURL, isSupportedShare } = useShare()
    const { logData, updateLogData, clearLogData } = useLogData()
    const { clearVideoData } = useVideoData()
    const { paymentMethods, fetchPaymentMethods, mapTeams } = useGame()

    const USER_ACT_VISIT_MATCH_LANDING_APP = 'Visit Match Landing App'
    const USER_ACT_SHARE_MATCH_LANDING_APP = 'Share Match Landing App'
    const USER_ACT_VIEW_MATCH_LANDING_APP = 'View Match Landing App'
    const ipAddress = ref({})

    const isAndroidComputed = computed(() => isAndroid())
    const isIOSComputed = computed(() => isIOS())

    const deepLinkVideo = ref('fanaty://match_detail/')
    const deepLinkVideoIOS = ref(
      `${isDev.value ? 'https://dev-console.fanaty.com' : 'https://console.fanaty.com'}/match_detail/`,
    )

    const dataLanding = ref({})
    const dataComputed = computed(() => dataLanding.value)
    const showComplexComputed = computed(
      () => dataComputed.value.complex_images
        && dataComputed.value.complex_images.length
        && dataComputed.value.complex_description,
    )
    const activeComputed = computed(() => dataComputed.value.match_status === 'Active')
    const basicComputed = computed(() => dataComputed.value.match_status === 'Basic')
    const computedMatchCode = computed(() => JSON.parse(localStorage.getItem('matchCode')))
    const depositCardComputed = computed(() => {
      if (dataComputed.value.deposit_card && dataComputed.value.deposit_card.ending4) return dataComputed.value.deposit_card.ending4

      return ''
    })
    const showPayComputed = computed(() => {
      if (
        dataComputed.value.reservation_status !== 'cancelled'
        && dataComputed.value.payment_status === 'pending'
        && dataComputed.value.subtotal > 0
      ) return true

      return false
    })
    const matchCode = ref(null)
    const matchId = ref(null)
    const privateLanding = ref(false)
    const isDialogReport = ref(false)
    const isDialogDownload = ref(false)
    const isDialogCancel = ref(false)
    const isDialogPay = ref(false)
    const isDialogShare = ref(false)

    const fetchMatch = async () => {
      // const response = await getReservation(matchCode.value)
      const response = await getMatchGameCode(matchCode.value)
      if (response.ok) {
        dataLanding.value = response.data
        matchId.value = dataLanding.value.match_id
      } else {
        router.push({
          name: 'reservation-not-found',
          params: {
            inactive: false,
            invalid: true,
          },
        })
      }
    }

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = computedMatchCode.value
      logData.value.redirect = 'reservation'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []
      privateLanding.value = false
    }

    const resolvePosterIcon = status => {
      if (status === 'Active') return mdiPlay
      if (status === 'Processing') return mdiCircle
      if (status === 'Basic' || status === 'Recording') return mdiCircle
      if (status === 'Cancelled') return mdiCancel

      return mdiCircle
    }

    const resolvePosterIconColor = status => {
      if (status === 'Basic') return 'info'
      if (status === 'Active' || status === 'Premiun' || status === 'Recording' || status === 'Cancelled') return 'secondary'

      return 'secondary'
    }

    const fetchLogUserActivity = async action => {
      await logUserActivity({
        ip_address: privateLanding.value ? null : ipAddress.value.ip,
        user_id: privateLanding.value ? userData.value.user_id : null,
        match_code: matchCode.value,
        reference: null,
        action,
      })
    }

    const openUpdateCancel = async val => {
      isDialogCancel.value = val
    }
    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const openUpdateDownload = async val => {
      isDialogDownload.value = val
    }

    const openCloseReport = async val => {
      isDialogReport.value = val
    }

    const linkVideo = () => {
      if (isAndroid() || isIOS()) {
        const dLink = `${deepLinkVideo.value}${matchId.value}`

        const dLinkOISO = `${deepLinkVideoIOS.value}${matchId.value}`
        if (isIOS()) {
          // eslint-disable-next-line no-alert
          if (isDev.value) alert(`deepLinkVideoIOS value: ${dLinkOISO}`)
          openTab(dLinkOISO)
        } else {
          // eslint-disable-next-line no-alert
          if (isDev.value) alert(`deepLinkVideoAndroid value: ${dLink}`)
          window.location = dLink
        }
      }

      openUpdateDownload(true)
    }

    const onReportIssue = async val => {
      const resp = await reportIssue(matchCode.value, val)
      if (resp.ok) {
        isDialogReport.value = false
        success(resp.message)
        await fetchMatch()
      }
    }

    const onCancelReservation = async () => {
      openUpdateCancel(false)
      const response = await putCancelReservation(matchCode.value)
      if (response.ok) {
        await fetchMatch()
        success(response.message)
      }
    }

    const formatTime = time => {
      const [min] = time.split('mins')

      return `${min} mins`
    }

    const onShare = () => {
      try {
        if (isSupportedShare.value) {
          shareURL(
            'Fanaty',
            `Date: ${dataComputed.value.reservation_date_str}\nTime: ${
              dataComputed.value.start_time_formatted
                ? `${dataComputed.value.start_time_formatted} (${formatTime(dataComputed.value.reservation_time)})`
                : ''
            }\nLocation: ${
              dataComputed.value.complex_name
                ? `${dataComputed.value.complex_name} • ${dataComputed.value.pitch_name}`
                : ''
            }\n\nManage booking:\n${dataComputed.value.share_link}\n\nVideo recording available via the link.`,
            dataComputed.value.share_link,
          )
        } else {
          openUpdateShare(true)
        }
      } catch (e) {
        console.error(e)
        openUpdateShare(true)
      }
    }

    const textBreakLine = text => {
      if (text) {
        return text
          .split('.')
          .filter(e => e)
          .map(e => `<p class="ma-0">${e}.</p><br />`)
          .join('')
      }

      return '<span></span>'
    }

    const onPay = async value => {
      const resp = await putHoldCardReservation(matchCode.value, value)
      if (resp.ok) {
        openUpdatePay(false)
        await fetchMatch()
      } else error(resp.message.text)
    }

    watch([route], async () => {
      await fetchMatch()

      await fetch('https://api.ipify.org/?format=json')
        .then(ipResponse => ipResponse.json())
        .then(async ipData => {
          ipAddress.value = ipData
        })
    })

    const resolveVideoCardBorder = computed(() => {
      if (
        dataComputed.value.include_video === 'N'
        && ['pending', 'declined'].includes(dataComputed.value.payment_status)
      ) return 'cancelled'
      if (
        dataComputed.value.include_video === 'Y'
        && ['pending', 'declined'].includes(dataComputed.value.payment_status)
        && dataComputed.value.subtotal > 0
      ) return 'pending'

      return 'primary'
    })

    const resolveVideoCardStatus = computed(() => {
      if (dataComputed.value.include_video === 'N' && dataComputed.value.subtotal === 0) return t('tooltip.disabled')
      if (dataComputed.value.include_video === 'N') return t('status.cancelled')
      if (
        dataComputed.value.include_video === 'Y'
        && (['hold', 'paid'].includes(dataComputed.value.payment_status) || dataComputed.value.subtotal === 0)
      ) return t('tooltip.enabled')
      if (
        dataComputed.value.include_video === 'Y'
        && ['pending', 'declined'].includes(dataComputed.value.payment_status)
      ) return t('status.pending')

      return ''
    })

    const resolveVideoCardText = computed(() => {
      if (
        dataComputed.value.include_video === 'N'
        && (dataComputed.value.reservation_status === 'cancelled'
          || ['hold', 'paid'].includes(dataComputed.value.payment_status))
      ) return t('reservations.this_would_not_be_recorded')
      if (
        dataComputed.value.include_video === 'N'
        && ['pending', 'declined'].includes(dataComputed.value.payment_status)
      ) return t('reservations.reservation_has_been_cancelled_since')
      if (
        dataComputed.value.include_video === 'Y'
        && ['pending', 'declined'].includes(dataComputed.value.payment_status)
      ) {
        return `${t('reservations.pay_for_booking')} ${
          dataComputed.value.reservation_date_str
            ? dataComputed.value.reservation_date_str.split(', ')[1].replace('.', '')
            : ''
        } ${dataComputed.value.start_time_formatted ? dataComputed.value.start_time_formatted : ''} ${t(
          'reservations.to_activate_video',
        )}`
      }
      if (dataComputed.value.include_video === 'Y' && ['hold', 'paid'].includes(dataComputed.value.payment_status)) return t('reservations.video_recording_text')

      return ''
    })

    onMounted(async () => {
      const { name } = route.value
      if (name === 'public') await clearLogData()
      await clearVideoData()

      const { code } = route.value.params

      // const { reauth, code } = route.value.params
      // if (reauth) window.location.reload()
      matchCode.value = code
      localStorage.setItem('matchCode', JSON.stringify(code))

      await fetchMatch()

      if (!userData.value) {
        await fetch('https://api.ipify.org/?format=json')
          .then(ipResponse => ipResponse.json())
          .then(async ipData => {
            ipAddress.value = ipData
          })
      } else {
        await fetchPaymentMethods()
        privateLanding.value = true
      }
    })

    return {
      USER_ACT_VISIT_MATCH_LANDING_APP,
      USER_ACT_SHARE_MATCH_LANDING_APP,
      USER_ACT_VIEW_MATCH_LANDING_APP,
      ipAddress,
      userData,
      isDev,
      paymentMethods,
      deepLinkVideo,
      deepLinkVideoIOS,
      matchId,

      matchCode,
      privateLanding,
      isDialogReport,
      isDialogDownload,
      isDialogCancel,
      isDialogPay,
      isDialogShare,

      dataComputed,
      depositCardComputed,
      showComplexComputed,
      activeComputed,
      basicComputed,
      showPayComputed,

      isAndroidComputed,
      isIOSComputed,

      appLogoTitle: themeConfig.app.logoTitle,

      avatarText,
      openTab,
      onLogin,
      resolvePosterIcon,
      resolvePosterIconColor,
      onShare,
      linkVideo,
      fetchMatch,
      onCancelReservation,
      openCloseReport,
      onReportIssue,
      openUpdateDownload,
      openUpdateCancel,
      openUpdatePay,
      openUpdateShare,
      textBreakLine,
      fetchLogUserActivity,
      onLogout,
      onPay,
      defaultDownloadAppByOS,
      formatTime,
      resolveVideoCardStatus,
      resolveVideoCardText,
      resolveVideoCardBorder,
      formatCurrency,
      mapTeams,

      t,

      icons: {
        mdiTrayArrowUp,
        mdiDotsVertical,
        mdiCircle,
        mdiMapMarker,
        mdiCancel,
        mdiPlay,
        mdiStarOutline,
        mdiVideo,
        mdiPencil,
        mdiMovieOpenPlayOutline,
        mdiHelpCircleOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';
.game-wrapper {
  background-color: #222222;
}

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>
